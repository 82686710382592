<template>
  <app-icon-btn
    wrapper-class="d-flex"
    :disabled="disabledBtn"
    :color-tooltip="disabledPersonalWs ? 'red' : ''"
    :text="disabledBtnText"
    bottom
    :show-tooltip="disabledBtn"
    class="collection-action-button"
    icon-name="mdi-content-copy"
    :icon-height="iconSize"
    @click="copy">
    <p
      class="small-p">
      Copy
    </p>
  </app-icon-btn>
</template>
<script>
import {
  mapMutations, mapActions, mapGetters, mapState,
} from 'vuex';
import { COLLECTION } from '@/constants/cores';
import {
  ROUTE_WORKSPACE_PAGE, ROUTE_WORKSPACE_PAGE_EDIT, TYPE_LIBRARY_CUSTOM,
} from '@/constants';
import { accessHelper } from '@/utils';
import {
  GET_TEXT_REASON, TEXT_COPY_CREATED_PERSONAL_WS,
} from '@/constants/userPermissions';
import { getUserRolesListById } from '@/utils/accessLayer';
export default {
  name: 'CollectionCopy',
  props: {
    collectionId: {
      type: String,
      default: '',
    },
    isLibrary: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
    iconSize: {
      type: Number,
      default: 16,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    group: {
      type: String,
      default: '',
    },
    isCommunityCollections: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('UserRoles', ['canCopyCollectionInCustomLibrary', 'canCopyCollectionInDefaultLibrary', 'findUserRoleInLibraryForActiveHeader']),
    ...mapGetters(['userId']),
    ...mapGetters('Workspace', ['disabledPersonalWs']),
    ...mapGetters('Collections', ['getPrivateCollectionsListLength', 'isUnlistedPublishActiveCollection']),
    ...mapState(['activeHeader', 'accessToken']),
    disabledBtn() {
      const { disabledPersonalWs, canWorkWithCopy, accessToken, disabled } = this;
      return disabledPersonalWs ? disabledPersonalWs : (!canWorkWithCopy.allowed || disabled) && !accessToken;
    },
    disabledBtnText() {
      return this.disabledPersonalWs ? TEXT_COPY_CREATED_PERSONAL_WS : GET_TEXT_REASON(this.canWorkWithCopy.reason);
    },
    isInCommunityCollection() {
      return this.$route.name == 'community-collection';
    },
    isWorkspacePage() {
      return this.$route.name == ROUTE_WORKSPACE_PAGE;
    },
    isWorkspacePageEdit() {
      return this.$route.name == ROUTE_WORKSPACE_PAGE_EDIT;
    },
    isInCommunityCollectionLibrary() {
      return this.$route.name == 'community-collections';
    },
    canWorkWithCopy() {
      const { members, type = '' } = this.item;
      if (this.isInCommunityCollectionLibrary
        || this.isWorkspacePage
        || this.isCommunityCollections
        || this.isWorkspacePageEdit
        || this.$route.name == 'collection-library'
        && this.activeHeader?.follow
      ) {
        return accessHelper(true);
      }
      let canCopy = this.canCopyCollectionInDefaultLibrary;
      const userRoles = getUserRolesListById({
        userId: this.userId,
        users: members || [],
      });
      if (this.activeHeader?.docType == COLLECTION) {
        if (this.$route.name == 'collection-library' && this.$route.params.libraryId) {
          canCopy = this.canCopyCollectionInCustomLibrary;
        }
        if (this.isInCommunityCollection) {
          return accessHelper(true);
        }
        if (members) {
          return canCopy(userRoles);
        }
        return canCopy(this.findUserRoleInLibraryForActiveHeader);
      }
      if (this.activeHeader?.kind == TYPE_LIBRARY_CUSTOM) {
        canCopy = this.canCopyCollectionInCustomLibrary;
      }
      if (members && !(this.isUnlistedPublishActiveCollection && this.group == 'followed')) {
        return canCopy(userRoles);
      }
      return canCopy(members ? userRoles : type);
    },
  },
  methods: {
    ...mapActions({
      copyCollection: 'Collections/copyCollection',
    }),
    ...mapMutations(['spinner', 'openSnackBar']),
    GET_TEXT_REASON,
    async copy() {
      if (this.disabledBtn) {
        return;
      }
      let confirmCopyItem = await this.$openConfirm({
        text: `Do you want to copy this collection ?`,
      });
      if (confirmCopyItem) {
        this.spinner(true);
        const { collectionId } = this;
        const community = this.isInCommunityCollection
          || this.isWorkspacePageEdit
          || this.isWorkspacePage
          || this.isInCommunityCollectionLibrary;
        const { suggestionResource = null, url = null, suggested = false } = this.item;
        await this.copyCollection({
          collectionId,
          community,
          suggested: suggested || !!suggestionResource,
          url,
        });
        this.openSnackBar({
          text: 'This Collection has been copied. You can begin to edit it on your Collection page.',
        });
        this.spinner(false);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.v-icon {
  cursor: pointer;
}
.small-p {
  padding: 0 !important;
}
</style>
